body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  background: #010013;
  overflow-x: hidden;
}
.blue-opacity {
  width: 900px;
  height: 240px;
  left: -258.67px;
  top: 0px;
  background: #19d2ff;
  opacity: 0.7;
  filter: blur(280.278px);
  transform: matrix(0.87, -0.5, -0.5, -0.87, 0, 0);
  position: absolute;
  z-index: -1;
  animation: blue-gradient-ani 1s linear 1;
}

.stars,
.stars-bg,
.bg-planet {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: auto;
  height: auto;
  display: block;
  z-index: -1;
}
.bg-planet {
  /* background: transparent url("./images/hh.png") no-repeat top center;
  background-size: 100%;
  top: 20%;
  z-index: -1; */
}
.stars {
  background: transparent url("./images/stars.png") repeat top center;
  z-index: -1;
  animation: fadeInAnimation ease 5s;
  animation-iteration-count: infinite;
  animation-fill-mode: forwards;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0.3;
  }
  25% {
    opacity: 0.6;
  }
  50% {
    opacity: 0.9;
  }
  75% {
    opacity: 0.6;
  }
  100% {
    opacity: 0.3;
  }
}
@keyframes move-twink-back {
  from {
    background-position: 0 0;
  }

  to {
    background-position: -10000px 5000px;
  }
}

.coin {
  display: inline-block;
  width: 50px;
  -webkit-animation: spin 3s cubic-bezier(0.3, 2, 0.4, 0.8) infinite;
  animation: spin 3s cubic-bezier(0.3, 1, 0.4, 0.8) infinite;
  /* animation: spin 1s linear infinite;
      animation-delay: 2s; */
  animation-direction: alternate-reverse;
  transform-style: preserve-3d;
  vertical-align: middle;
}
.coin.left {
  top: 20%;
  left: 3%;
  position: absolute;
}
.coin.right {
  top: 48%;
  right: 6%;
  position: absolute;
  z-index: -1;
}

@-webkit-keyframes spin {
  0%,
  10% {
    transform: rotate(-10deg) perspective(400px);
  }

  90%,
  100% {
    transform: rotate(-10deg) perspective(400px) rotateY(180deg);
  }
}

@keyframes spin {
  0%,
  10% {
    transform: rotate(-10deg) perspective(400px);
  }

  90%,
  100% {
    transform: rotate(-10deg) perspective(400px) rotateY(180deg);
  }
}

.shooting_star_container {
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: auto;
  transform: rotateZ(45deg);
}

.shooting_star {
  z-index: -1;
  position: absolute;
  left: 50%;
  top: 10%;
  height: 2px;
  background: linear-gradient(-45deg, #5f91ff, rgba(0, 0, 255, 0));
  border-radius: 999px;
  filter: drop-shadow(0 0 6px #699bff);
  -webkit-animation: tail 3s ease-in-out infinite,
    shooting 3s ease-in-out infinite;
  animation: tail 3s ease-in-out infinite, shooting 3s ease-in-out infinite;
}

@keyframes tail {
  0% {
    width: 0;
  }

  30% {
    width: 100px;
  }

  100% {
    width: 0;
  }
}

@keyframes shooting {
  0% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(300px);
  }
}

.planet-l {
  position: absolute;
  width: 100px;
  height: 100px;
  left: -70px;
  top: 40%;
  background: linear-gradient(
    155.22deg,
    rgba(61, 172, 234, 0.46) -15.38%,
    rgba(17, 9, 68, 0) 91.72%
  );
  opacity: 0.3;
  border-radius: 100%;
}

.planet {
  position: absolute;
  width: 100px;
  height: 100px;

  border-radius: 100%;
  overflow: hidden;
  box-shadow: 0 0 60px -20px rgb(255 255 255 / 9%),
    -14px -15px 40px -10px rgb(255 255 255 / 11%);
}
.planet.left {
  left: -50px;
  top: 40%;
}

.planet.right {
  right: -50px;
  top: 20%;
}
.planet .background {
  /* animation: translateBackground 40s infinite linear; */
  background: url("./images/planet11.png") repeat-x;
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  opacity: 0.5;
  background-size: 100%;
}
.planet .mask {
  width: 100%;
  height: 100%;
  position: absolute;
  box-shadow: inset -10px -10px 40px #0e2a4282,
    inset 10px 10px 30px -10px rgb(255 255 255 / 0%);
  border-radius: 50%;
}
@keyframes translateBackground {
  0% {
    background-position-x: 0;
  }

  100% {
    background-position-x: -600px;
  }
}
.planet .wrap {
  width: 100px;
  height: 100px;
  position: absolute;
  border-radius: 50%;
  animation: rotatePlanet 2s infinite linear;
}
@keyframes rotatePlanet {
  0% {
    transform: rotateZ(0);
  }

  100% {
    transform: rotateZ(-360deg);
  }
}

@keyframes blue-gradient-ani {
  from {
    opacity: 0;
  }

  to {
    opacity: 0.7;
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
@media screen and (max-width: 1200px) {
  .planet,
  .shooting_star_container,
  .coin {
    display: none;
  }

  .blue-opacity {
    width: 400px;
    height: 240px;
    left: -158.67px;
  }
  .shooting_star_container {
    display: none;
  }
}
.web3modal-modal-lightbox {
  background-color: transparent;
}
